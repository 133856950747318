import axios from 'axios';
import React, { useContext, useState, useEffect } from 'react';
import { apiURl } from '../config';
import { IoCloudUploadOutline } from 'react-icons/io5';
import toast from 'react-hot-toast';
import { CreateFileContext } from '../context/fileContext';
import { VscDiscard } from 'react-icons/vsc';

const FileUpload = ({
  modalOpen,
  setModalOpen,
  selectedFile,
  setSelectedFile,
  inputKey,
  setInputKey,
}) => {
  const [fileuploadLoading, setFileuploadLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [message, setMessage] = useState();
  const [showDualScreen, setShowDualScreen] = useState(false);
  const [fileContent, setFileContent] = useState(null);
  const { setFileListUpdated } = useContext(CreateFileContext);

  // Handle preview generation and automatic upload
  useEffect(() => {
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      setPreviewUrl(url);
      uploadFile();

      return () => URL.revokeObjectURL(url);
    } else {
      setPreviewUrl(null);
      setShowDualScreen(false);
      setModalOpen(false);
      setFileContent(null);
      deselectFile();
      setShowDualScreen(false);
      setMessage('');
    }
  }, [selectedFile]);

  const deselectFile = () => {
    setModalOpen(false);
    setSelectedFile(null);
    setPreviewUrl(null);
    setShowDualScreen(false);
    setFileContent(null);
    setInputKey((prevKey) => prevKey + 1);
  };

  const uploadFile = async () => {
    if (!selectedFile) return;

    setFileuploadLoading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const res = await axios.post(apiURl + '/invoice-check', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (res.data.error) {
        toast.error(res.data.message);
        setMessage(res.data.message);
      } else {
        toast.success(res.data.message);
        setMessage(res.data.message);
        getFile(res?.data?.file_location);
        console.log('File uploaded successfully');
        setFileListUpdated((prev) => !prev);
        setShowDualScreen(true);
      }
    } catch (error) {
      toast.error(error.message || 'Upload failed');
      console.error('Error:', error);
    } finally {
      setFileuploadLoading(false);
    }
  };

  const getFile = (file) => {
    axios
      .get(apiURl + `/${'get-file'}/` + file)
      .then((res) => {
        if (res.data.error) {
          return console.log(res.data.message);
        } else {
          setFileContent(res.data.pdf_content); // Assuming this is raw base64 without prefix
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const isImage = selectedFile?.type.startsWith('image/');
  const isPDF = selectedFile?.type === 'application/pdf';

  // Determine file type based on extension for processed file
  const fileExtension = selectedFile?.name.split('.').pop().toLowerCase();
  const isProcessedImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
  const isProcessedPDF = fileExtension === 'pdf';

  // Add appropriate data URI prefix to base64 content
  const getProcessedFileUrl = () => {
    if (!fileContent) return null;

    if (isProcessedImage) {
      const mimeType =
        fileExtension === 'png'
          ? 'image/png'
          : fileExtension === 'gif'
          ? 'image/gif'
          : 'image/jpeg';
      return `data:${mimeType};base64,${fileContent}`;
    }
    if (isProcessedPDF) {
      return `data:application/pdf;base64,${fileContent}`;
    }
    return null;
  };

  const processedFileUrl = getProcessedFileUrl();

  return (
    <div className="flex flex-col items-center justify-center gap-2 w-full">
      {selectedFile && (
        <div className="w-full m-2 p-4 bg-transparent rounded-xl">
          {!showDualScreen ? (
            <div className="flex flex-col items-center">
              <h2 className="text-xl text-center m-2">{selectedFile?.name}</h2>
              <div className="flex gap-2 w-full items-center justify-around mt-8">
                <button
                  className={`btn btn-outline min-w-[7rem] btn-primary bg-white !text-slate-800 border-none border-slate-100 
                  hover:!bg-base-200 hover:!text-white ${fileuploadLoading ? 'btn-disabled' : ''}`}
                  disabled={fileuploadLoading}
                >
                  {fileuploadLoading ? (
                    <div className="flex justify-center items-center gap-2">
                      Uploading
                      <div className="loading-spinner" />
                    </div>
                  ) : (
                    <div className="flex justify-center items-center gap-2">
                      Upload <IoCloudUploadOutline size={25} />
                    </div>
                  )}
                </button>
                <button
                  onClick={deselectFile}
                  className={`btn btn-primary min-w-[7rem] ${fileuploadLoading && 'btn-disabled'}`}
                  disabled={fileuploadLoading}
                >
                  Discard <VscDiscard size={25} />
                </button>
              </div>
            </div>
          ) : (
            <div>
              <p>{message}</p>
              <div className="flex flex-col md:flex-row gap-4">
                {/* Left side - User Selected File Preview */}
                <div className="w-full md:w-1/2 flex flex-col items-center">
                  <h2 className="text-xl text-center m-2">{selectedFile?.name}</h2>
                  {previewUrl && (
                    <div className="max-w-full max-h-[400px] overflow-auto border rounded mb-4">
                      {isImage && (
                        <img
                          src={previewUrl}
                          alt="Selected File Preview"
                          className="max-w-full h-auto"
                        />
                      )}
                      {isPDF && (
                        <iframe src={previewUrl} title="PDF Preview" className="w-full h-[400px]" />
                      )}
                      {!isImage && !isPDF && (
                        <div className="p-4 text-center text-gray-500">
                          Preview not available for this file type
                        </div>
                      )}
                    </div>
                  )}
                  <div className="flex gap-2 w-full items-center justify-around">
                    <button
                      className="btn btn-outline min-w-[7rem] btn-primary bg-white !text-slate-800 border-none border-slate-100 
                   hover:!bg-base-200 hover:!text-white"
                      disabled
                    >
                      Uploaded
                    </button>
                    <button
                      onClick={() => {
                        deselectFile();
                        setShowDualScreen(false);
                        setMessage('');
                      }}
                      className="btn btn-primary min-w-[7rem]"
                    >
                      Close <VscDiscard size={25} />
                    </button>
                  </div>
                </div>

                {/* Right side - File Content from Server */}
                <div className="w-full md:w-1/2 flex flex-col items-center">
                  <h2 className="text-xl text-center m-2">Processed File</h2>
                  {fileContent ? (
                    <div className="max-w-full max-h-[400px] overflow-auto border rounded">
                      {isProcessedImage && processedFileUrl && (
                        <img
                          src={processedFileUrl}
                          alt="Processed File Preview"
                          className="max-w-full h-auto"
                        />
                      )}
                      {isProcessedPDF && processedFileUrl && (
                        <iframe
                          src={processedFileUrl}
                          title="Processed PDF Preview"
                          className="w-full h-[400px]"
                        />
                      )}
                      {!isProcessedImage && !isProcessedPDF && (
                        <div className="p-4 text-center text-gray-500">
                          Unable to preview processed file content
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="p-4 text-center text-gray-500">Loading processed file...</div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;

import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { FaPlus, FaMinus } from 'react-icons/fa';

function ViewPdf({ fileContent, selectedFile, setShowPdf, setFileContent, handleExpandClick }) {
  const isImage = selectedFile?.match(/\.(jpeg|jpg|png|gif)$/i);
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = () => {
    setZoomLevel((prev) => Math.min(prev + 0.2, 3)); // Max zoom 300%
  };

  const handleZoomOut = () => {
    setZoomLevel((prev) => Math.max(prev - 0.2, 0.2)); // Min zoom 20%
  };

  const renderContent = () => {
    if (isImage) {
      return (
        <div className="w-full h-full relative overflow-auto">
          <div className="absolute top-2 right-2 flex gap-2 z-10">
            <button
              onClick={handleZoomIn}
              className="bg-gray-200 p-2 rounded hover:bg-gray-300"
              disabled={zoomLevel >= 3}
            >
              <FaPlus />
            </button>
            <button
              onClick={handleZoomOut}
              className="bg-gray-200 p-2 rounded hover:bg-gray-300"
              disabled={zoomLevel <= 0.2}
            >
              <FaMinus />
            </button>
          </div>
          <div
            className="w-full h-full flex items-center justify-center"
            style={{ minWidth: '100%', minHeight: '100%' }}
          >
            <img
              src={`data:image/${selectedFile.split('.').pop()};base64,${fileContent}`}
              alt={selectedFile}
              className="object-contain"
              style={{
                transform: `scale(${zoomLevel})`,
                transition: 'transform 0.2s ease-in-out',
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-full h-full relative">
          <object
            data={`data:application/pdf;base64,${fileContent}`}
            type="application/pdf"
            width="96%"
            height="96%"
            className="absolute right-0"
          ></object>
        </div>
      );
    }
  };

  return (
    <>
      <div>
        {selectedFile && (
          <div className="nav mt-1">
            <div
              onClick={() => {
                setShowPdf(false);
                setFileContent(null);
                handleExpandClick();
              }}
              className="ml-4 cursor-pointer hover:brightness-90"
            >
              <IoArrowBackCircleOutline size={26} />
            </div>
            <h3 className="font-bold text-orange-400" style={{ padding: '10px' }}>
              Selected File: {selectedFile}
            </h3>
          </div>
        )}
        <Card className="h-[70vh] pb-1">{fileContent && renderContent()}</Card>
      </div>
    </>
  );
}

export default ViewPdf;

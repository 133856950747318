import React, { useContext, useEffect, useState } from 'react';
import useResizable from '../hooks/useResizable';
import { PiEquals } from 'react-icons/pi';
import { CreateFileContext } from '../context/fileContext';
import axios from 'axios';
import { apiURl } from '../config';
// import { ToastContainer, toast } from 'react-toastify';
import { MdDelete, MdOutlineCancel } from 'react-icons/md';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { VscEye } from 'react-icons/vsc';
import ViewPdf from './ViewPdf';
import { HiOutlineDownload } from 'react-icons/hi';
import Modal from './Modal';
import FileDelete from './FileDelete';
import { FaFile } from 'react-icons/fa';

// Mui components
import { PictureAsPdf, SelectAll } from '@mui/icons-material';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';

function ListFiles() {
  const { width, isResizing, handleMouseDown } = useResizable(500);
  const [apiResponse, setApiResponse] = useState(null);
  const [docList, setDocList] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [deleteFile, setDeleteFile] = useState(null);
  const [showPdf, setShowPdf] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const [fileHovered, setFileHovered] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(localStorage.getItem('activeTreeItem') || '');

  const handleSelect = (itemId) => {
    setActiveItem(itemId);
    localStorage.setItem('activeTreeItem', itemId); // Store in localStorage
  };
  const { getData, fileListUpdated, refreshFileExtInLocalStorage } = useContext(CreateFileContext);

  // Load the list of files
  const getFileList = () => {
    axios
      .post(apiURl + `/list`)
      .then((res) => {
        if (res.data.error) {
          return console.log(res.data.message);
        } else {
          // console.log(res.data[0]['file_names']);
          setApiResponse(res.data[0]['file_names']);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    axios
      .post(apiURl + `/doc_list`)
      .then((res) => {
        if (res.data.error) {
          return console.log(res.data.message);
        } else {
          setDocList(res.data[0]['file_names']);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  // React.useMemo(() => {
  //   getFileList();
  // }, []);

  /* OLD CODE */

  useEffect(() => {
    getFileList();
    setSelectedFile(localStorage.getItem('fileName'));
  }, [fileListUpdated]);

  const setFile = (value) => {
    getData(value);
    const isPDF = value.toLowerCase().endsWith('.pdf');
    const isImage =
      value.toLowerCase().endsWith('.jpg') ||
      value.toLowerCase().endsWith('.jpeg') ||
      value.toLowerCase().endsWith('.png') ||
      value.toLowerCase().endsWith('.gif') ||
      value.toLowerCase().endsWith('.bmp') ||
      value.toLowerCase().endsWith('.webp') ||
      value.toLowerCase().endsWith('.svg');
    if (isPDF) {
      // toast.success('PDF Selected');
      localStorage.setItem('fileName', value);
      setSelectedFileType('PDF');
      localStorage.setItem('fileType', 'PDF');
    } else if (isImage) {
      localStorage.setItem('fileName', value);
      setSelectedFileType('Image');
      localStorage.setItem('fileType', 'Image');
    } else {
      // toast.success('File Selected');
      localStorage.setItem('fileName', value);
      setSelectedFileType('Excel');
      localStorage.setItem('fileType', 'Excel');
    }
    getFile(value);
    setSelectedFile(value);
    refreshFileExtInLocalStorage();
  };
  const getFile = (file) => {
    axios
      .get(apiURl + `/${'get-file'}/` + file)
      .then((res) => {
        if (res.data.error) {
          return console.log(res.data.message);
        } else {
          setFileContent(res.data.pdf_content);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  const viewPDF = (file) => {
    setFile(file);
    setShowPdf(true);
  };

  const deselectFile = () => {
    localStorage.removeItem('fileName');
    localStorage.removeItem('fileType');
    setSelectedFileType(null);
    setSelectedFile(null);
  };

  const handleDownloadClick = async (fileName) => {
    let base64String;
    await axios
      .get(apiURl + `/get-file/` + fileName)
      .then((res) => {
        if (res.data.error) {
          return console.log(res.data.message);
        } else {
          base64String = res.data.pdf_content;
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    const downloadLink = document.createElement('a');
    downloadLink.href = `data:application/pdf;base64,${base64String}`;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const sapOperations = ['HR Operations', 'Supply Chain', 'CRM'];
  const giplOperations = ['Docs', 'Website'];
  // const sapOperations = [
  //   'Data Analysis and Reporting from SAP',
  //   'Predictive Maintenance',
  //   'Supply Chain Optimization',
  //   'AI-Enhanced Quality Control',
  //   'Comprehensive Inventory Tracking',
  //   'Dynamic Production Planning',
  //   'Intuitive Workflow Automation',
  //   'Smart Calendar Management',
  //   'HR Practices and Policy Guidance',
  // ];

  return (
    <div
      className="w-screen h-full overflow-hidden relative lg:w-[50%] rounded-xl rounded-l-none bg-primary bg-opacity-10"
      style={{ width: `${width}px` }}
    >
      <div className="flex justify-between chatui-fontColor items-center p-4 pt-6 border-b-0 border-l-0 rounded-xl rounded-tl-none rounded-b-none font-semibold text-xl">
        {/* <p className="select-none flex justify-start w-full">List of Files</p> */}
      </div>
      <div
        onMouseDown={handleMouseDown}
        className={`hidden md:flex hover:bg-neutral/70 active:bg-neutral/80 items-center z-40 absolute bottom-0 cursor-col-resize h-full w-[15px] overflow-hidden bg-transparent ${
          isResizing ? 'pointer-events-none' : ''
        }`}
      >
        <PiEquals className="chatui-fontColor rotate-90" />
      </div>
      <div className="px-3 pt-2 h-[85vh] overflow-y-scroll w-screen md:w-full">
        {showPdf ? (
          <ViewPdf
            fileContent={fileContent}
            selectedFile={selectedFile}
            setShowPdf={setShowPdf}
            setFileContent={setFileContent}
          />
        ) : (
          <SimpleTreeView>
            {/*
            <TreeItem
              itemId="invoice"
              label={<div className="chatui-fontColor text-lg">Uploaded Invoice</div>}
              onClick={() => handleSelect('invoice')}
              // className={`${activeItem === 'invoice' ? 'rounded-md bg-[#E0E1FC]' : ''}`}
            >
              {apiResponse?.map((file, index) => {
                const fileExtension = file.split('.').pop();
                const icon =
                  fileExtension === 'pdf' ? (
                    <PictureAsPdf
                      sx={{ fontSize: '14px' }}
                      className={`text-[14px] ${
                        selectedFile === file ? 'text-white' : 'text-[#412BAC]'
                      }`}
                    />
                  ) : fileExtension === 'xlsx' || fileExtension === 'xls' ? (
                    <RiFileExcel2Fill
                      className={`text-[14px] ${
                        selectedFile === file ? 'text-white' : 'text-green-700'
                      }`}
                    />
                  ) : (
                    <FaFile
                      sx={{ fontSize: '14px' }}
                      className={`text-[14px] ${
                        selectedFile === file ? 'text-white' : 'text-[#412BAC]'
                      }`}
                    />
                  );

                return (
                  <TreeItem
                    key={`uploaded-${file}-${index}`}
                    itemId={`uploaded-${file}-${index}`}
                    className={`rounded-md ${selectedFile === file && 'bg-[#E0E1FC]'}`}
                    onClick={() => setFile(file)}
                    label={
                      <span
                        onMouseLeave={() => setFileHovered(null)}
                        onMouseEnter={() => setFileHovered(index)}
                        className={`w-full h-full select-none overflow-hidden flex gap-2 place-items-center py-[0.15rem] my-1`}
                      >
                        <div onClick={() => setFile(apiResponse[index])}>{icon}</div>
                        <div className="relative flex w-full justify-between overflow-hidden">
                          <h1
                            onClick={() => setFile(apiResponse[index])}
                            className="chatui-fontColor"
                          >
                            {apiResponse[index]}
                          </h1>
                          {selectedFile === file && (
                            <div className=" min-w-fit flex items-center justify-end gap-1 h-full absolute right-0">
                              <MdOutlineCancel
                                size={16}
                                onClick={deselectFile}
                                className="cursor-pointer z-30 hover:brightness-90 options-selected"
                              />
                              {fileExtension === 'pdf' || fileExtension === 'jpg' ? (
                                <VscEye
                                  size={16}
                                  onClick={() => viewPDF(apiResponse[index])}
                                  className="cursor-pointer hover:brightness-90 options-selected"
                                />
                              ) : (
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleDownloadClick(apiResponse[index]);
                                  }}
                                >
                                  <HiOutlineDownload
                                    size={16}
                                    className="cursor-pointer hover:brightness-90 options-selected"
                                  />
                                </a>
                              )}
                              <MdDelete
                                onClick={() => {
                                  setModalOpen(true);
                                  setDeleteFile(apiResponse[fileHovered]);
                                }}
                                size={16}
                                className="cursor-pointer hover:brightness-90 options-selected"
                              />
                            </div>
                          )}
                        </div>
                      </span>
                    }
                  />
                );
              })}
            </TreeItem>
            <TreeItem
              itemId="documents"
              label={<div className="chatui-fontColor text-lg">Uploaded Documents</div>}
              onClick={() => handleSelect('documents')}
              // className={`${activeItem === 'documents' ? 'rounded-md bg-[#E0E1FC]' : ''}`}
            >
              {docList?.map((file, index) => {
                const fileExtension = file.split('.').pop();
                const icon =
                  fileExtension === 'pdf' ? (
                    <PictureAsPdf
                      sx={{ fontSize: '14px' }}
                      className={`text-[14px] ${
                        selectedFile === file ? 'text-white' : 'text-[#412BAC]'
                      }`}
                    />
                  ) : fileExtension === 'xlsx' || fileExtension === 'xls' ? (
                    <RiFileExcel2Fill
                      className={`text-[14px] ${
                        selectedFile === file ? 'text-white' : 'text-green-700'
                      }`}
                    />
                  ) : (
                    <FaFile
                      sx={{ fontSize: '14px' }}
                      className={`text-[14px] ${
                        selectedFile === file ? 'text-white' : 'text-[#412BAC]'
                      }`}
                    />
                  );

                return (
                  <TreeItem
                    key={`upload-${file}-${index}`}
                    itemId={`upload-${file}-${index}`}
                    className={`rounded-md ${selectedFile === file && 'bg-[#E0E1FC]'}`}
                    onClick={() => setFile(file)}
                    label={
                      <span
                        onMouseLeave={() => setFileHovered(null)}
                        onMouseEnter={() => setFileHovered(index)}
                        className={`w-full h-full select-none overflow-hidden flex gap-2 place-items-center py-[0.15rem] my-1`}
                      >
                        <div onClick={() => setFile(docList[index])}>{icon}</div>
                        <div className="relative flex w-full justify-between overflow-hidden">
                          <h1 onClick={() => setFile(docList[index])} className="chatui-fontColor">
                            {docList[index]}
                          </h1>
                          {selectedFile === file && (
                            <div className=" min-w-fit flex items-center justify-end gap-1 h-full absolute right-0">
                              <MdOutlineCancel
                                size={16}
                                onClick={deselectFile}
                                className="cursor-pointer z-30 hover:brightness-90 options-selected"
                              />
                              {fileExtension === 'pdf' ? (
                                <VscEye
                                  size={16}
                                  onClick={() => viewPDF(docList[index])}
                                  className="cursor-pointer hover:brightness-90 options-selected"
                                />
                              ) : (
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleDownloadClick(docList[index]);
                                  }}
                                >
                                  <HiOutlineDownload
                                    size={16}
                                    className="cursor-pointer hover:brightness-90 options-selected"
                                  />
                                </a>
                              )}
                              <MdDelete
                                onClick={() => {
                                  setModalOpen(true);
                                  setDeleteFile(docList[fileHovered]);
                                }}
                                size={16}
                                className="cursor-pointer hover:brightness-90 options-selected"
                              />
                            </div>
                          )}
                        </div>
                      </span>
                    }
                  />
                );
              })}
            </TreeItem>
              */}
            <TreeItem
              itemId="worker"
              label={<div className="chatui-fontColor text-lg">GIPL</div>}
              onClick={() => handleSelect('worker')}
              // className={`${activeItem === 'worker' ? 'rounded-md bg-[#E0E1FC]' : ''}`}
            >
              {/* {giplOperations.map((item, index) => (
                <TreeItem
                  key={`gipl-${item}-${index}`}
                  itemId={`gipl-${item}-${index}`}
                  className={`rounded-md ${selectedFile === item && 'bg-[#E0E1FC]'}`}
                  onClick={() => setFile(item)}
                  label={
                    <span
                      onMouseLeave={() => setFileHovered(null)}
                      onMouseEnter={() => setFileHovered(index)}
                      className="w-full h-full select-none overflow-hidden flex gap-2 place-items-center py-[0.15rem] my-1"
                    >
                      <div onClick={() => setFile(item)}>
                        <SelectAll
                          sx={{ fontSize: '14px' }}
                          className={`text-[14px] ${
                            selectedFile === item ? 'text-white' : 'text-[#412BAC]'
                          }`}
                        />
                      </div>
                      <div className="relative flex w-full justify-between overflow-hidden">
                        <h1 onClick={() => setFile(item)} className="chatui-fontColor">
                          {item}
                        </h1>
                      </div>
                    </span>
                  }
                />
              ))} */}
            </TreeItem>
          </SimpleTreeView>
        )}
      </div>

      <Modal title="File Delete" modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <FileDelete
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          deleteFile={deleteFile}
          getFileList={getFileList}
        />
      </Modal>

      {/* <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      /> */}
    </div>
  );
}

export default ListFiles;

const daisyuiTheme = {
  themes: [
    {
      light: {
        primary: '#F7F7F7',
        secondary: '#F3F4F0',
        neutral: '#FFFFFF',
        accent: '#E0E1FC',
        'base-100': '#F3F4F0',
        'base-200': '#FA7224',
        'base-300': '#F3F4F0',
      },
      dark: {
        primary: '#242124',
        secondary: '#3D2B1F',
        neutral: '#1B1B1B',
        accent: '#A34D14',
        'base-100': '#0E111B',
        'base-200': '#FA7224',
        'base-300': '#05030F',
      },
    },
  ],
};

module.exports = daisyuiTheme;
